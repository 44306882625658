.project-list-item{
  overflow: hidden;
  height: 300px;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
  opacity:0.99;
  margin-bottom: 30px;
  box-shadow: 0 10px 10px rgba(0,0,0,.16);
  transition: all 250ms cubic-bezier(.02, .01, .47, 1);
  &:hover {
    box-shadow: 0 20px 20px rgba(0,0,0,.32);
    transform: translate(0,-5px);
    transition-delay: 0s !important;
    opacity:1.0;
  }
}

.project-list-item-wrap{
  display: block;
  height: 100%;
  position: relative;
}


.project-list-item__infos{
  color:white;
  top: 0;
  left: 0;
  padding: 26px 30px;
  font-size: 1em;
  max-width: 700px;
  font-family: $Lato;
}

.project-list-item__time{
  color: $theme-color;
  padding: 10px;
  background-color: #F7F7F5;
  width: 180px;
  font-size: 1.2em;
  font-weight: 400;
  max-width: 100%;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: bold;
}

.project-list-item__title{
  font-size: 2.5em;
  font-weight: $bold;
  font-family: $Oswald;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom:5px;
}
.project-list-item__subtitle{
  font-size: 1.5em;
  font-weight: 300;
  font-family: $Lato;
}

