hr .icon-row-divider{
  border-top: 1px solid #191919 !important;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

