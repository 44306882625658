.title-portfolio-content{
	font-size:2.3em;
	font-family:$Oswald;
	font-weight:500;
	color:$theme-color-full;
	padding-bottom:10px;
	text-transform:uppercase;
	letter-spacing:2px;
	background-image: -webkit-linear-gradient(90deg, #3175AF 0%, #1F5786 80%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: hue 60s infinite linear;

}
.tech-title{
	font-size:1.3em;
	font-family:$Lato;
	font-weight:400;
	color:$black-mine-shaft;
}
.portfolio-context{
	width:100%;
	h3{
		font-size: 1.4em;
	}
	div p{
		text-align:center;
		font-family: $Lato;
		font-weight:400;
		font-size:1.2em;
		i{
			font-size:3em;
			padding:15px;
			background-image: -webkit-linear-gradient(90deg, #3175AF 0%, #1F5786 80%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-webkit-animation: hue 60s infinite linear;
		}
	}
}

.portfolio-problem{
	background-color:white;
	background-image: -webkit-linear-gradient(45deg, #870000 0%, #190A05 80%);
	height:auto;
	width:100%;
	padding: 40px;
	border-radius: 5px;
	box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.3);
	p{
		font-size: 2em;
		font-weight: 400;
		font-family: $Lato;
		text-align:center;
		color:$beige;
	}
}

.center {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.trigger {
	position: relative;
	background: $theme-color;
	display: inline-block;
	width: 200px;
	height: 50px;
	color: $beige;
	border-radius: 50em;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .3), inset 0 1px 0 rgba(255, 255, 255, .2);
	span{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		transition: top .3s;
		top: 0;
		line-height: 50px;
		color:white;
		font-style: normal;
	}
}

.trigger::before,
.trigger::after {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.trigger::before {
	position: absolute;
	padding:5px;
	height: auto;
	background: #870000;
	border-radius: .25em;
	left: calc(50% - 100px);
	color:$beige;
}

.trigger:after {
	content: '';
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-top-color: #870000;
	left: calc(50% - 10px);
	bottom: 70px;
}

.trigger:hover::before,
.trigger:hover::after {
	opacity: 1;
	visibility: visible;
}

.trigger:hover::before {
	bottom: 60px;
}

.trigger:hover::after {
	bottom: 40px;
}
.tech-used{
	display:table;
}

@media (max-width: 470px){
	.tech-used{
		width:100%;
	}
}


