/* -------------------------------- 
Small Window Size and Mobile Style
-------------------------------- */

@media screen and (max-width: 875px) {
	.button-container {
		margin: 10px 10px !important;
	}
}

section.page-container {
	margin: auto;
	width: 90%;
	height:80px;
	max-width: 800px;
	position: relative;
	text-align: center;
}

.section-container {
	position: absolute;
	top: 60%;
	left: 50%;
	width: 90%;
	transform: translate(-50%, -50%);
	text-align: center;
}
/*** General Button Styles ***/

.button-slide-vertical {
	overflow: hidden;
	height: 45px;
}

.button-container {
	display: inline-block;
	margin: 10px 10px;
	cursor: pointer;
	font-weight: 400;
	letter-spacing: 2px;
	height: 45px;
	width: 200px;
	-webkit-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000; 
	box-shadow:0 0 10px 0px rgba(0, 0, 0, 0.25);
	border-radius:5px;
	transition: all 250ms cubic-bezier(.02, .01, .47, 1);
	&:hover{
		-webkit-box-shadow:0 0 15px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.5);
	}

	.button {
		background-image: linear-gradient(-45deg, #3175AF, #1F5786);
		overflow: hidden;
		height: 45px;
	}

	i {
		position: relative;
		margin: 0 !important;
		top: 10px;
		transition: all .3s ease-in-out;
		opacity: 1;			
	}

	i.back {
		font-family: $Lato;
		color: white;
		font-size: 15px;
		text-transform: uppercase;
		letter-spacing: 2px;
		transform: translateY(30px);
		opacity: 0;
	}

	&:hover i.front {
		transform: translateY(-30px);
		opacity: 0;			
	}

	&:hover i.back {
		transform: translateY(-25px);
		opacity: 1;			
	}

	.button a{
		height: 45px;
		width: 200px;
		border-radius: 3px;
		backface-visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		i{
			font-style: normal !important;
		}

		i.perso {
			font-family: Lato;
			text-transform: uppercase;
			color: $beige;
			margin: auto;
		}

		.front {
			z-index: 10;
			font-weight: 700;
			font-size: 1.2em;
		}

		.back {
			font-size: 15px;
		}
	}
}

