.social-network {
  float: right;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: opacity .35s ease .75s;
  z-index: 7; }

.social-network ul {
  list-style-type: none; }

.social-network i {
  font-size: 1.8em;
  width: auto;
  margin: 10px 0px;
  //color: rgba(255, 255, 255, 0.85);
  color:$beige;
}

.social-network i:hover {
  transform: scale(1.2);
  color: white; }
