//Azteclive Tooltip

#aztec-project::before {
	content: "To estimate the cost, anticipate the potential late and managing the employees.";
}
#aztec-word::before {
	content: "To write the deliverables.";
}
#aztec-sketch::before {
	content: "To design the User Interface.";
}
#aztec-invision::before {
	content: "To create the prototype from the UI.";
}
#aztec-quicktime::before {
	content: "To record the app working on the phone.";
}
#aztec-ae::before {
	content: "To add captions during the navigation in the app.";
}
#aztec-pp::before {
	content: "To put together the sound and the prototype with captions.";
}
#aztec-slides::before {
	content: "To create the final presentation.";
}

// Gotchu App Tooltip

#gotchuapp-react::before {
	content: "To develop the mobile app cross-platform.";
}
#gotchuapp-sketch::before {
	content: "To design the UI screen, the logo and others assets for social media.";
}
#gotchuapp-marvel::before {
	content: "To create the prototype from the UI and share it.";
}

// Portfolio Tooltip

#arakotozafy-html::before {
	content: "To structure my website.";
}
#arakotozafy-sass::before {
	content: "To better structure my stylesheets.";
}
#arakotozafy-gulp::before {
	content: "To automate front-end task for development & production.";
}
#arakotozafy-jquery::before {
	content: "To animate some part of the website.";
}
#arakotozafy-bower::before {
	content: "To manage my front-end package.";
}
#arakotozafy-npm::before {
	content: "To manage my development package.";
}
#arakotozafy-git::before {
	content: "To control and secure my previous version.";
}
#arakotozafy-do::before {
	content: "To host my website.";
}

// Wallobee Tooltip

#wallobee-sketch::before {
	content: "To design the UI of the apps.";
}
#wallobee-protoio::before {
	content: "To create the prototype from the UI.";
}
#wallobee-slides::before {
	content: "To create the pitch presentation.";
}
#wallobee-unsplash::before {
	content: "To get free to use image for the presentation.";
}
#wallobee-noun::before {
	content: "To get free to use vector icons.";
}
#wallobee-photoshop::before {
	content: "To integrate the screen into mockups.";
}
