.cards {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 20px;
	padding: 0;
}

.cards__item {
	display: flex;
	padding: 1rem;
	a{
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	@media(max-width: $screen-xs-max) {
		width: 50%;
	}
	@media(min-width: $screen-sm-min) {
		width: 33.3333%;
	}
	@media(max-width: $screen-xxs-max) {
		width: 100%;
	}
}

.card {
	background-color: white;
	box-shadow: 0 10px 10px rgba(0,0,0,.08);
	transition: all 250ms cubic-bezier(.02, .01, .47, 1);
	white-space:normal;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width:100%;
	margin-bottom:10px;
	&:hover {
		box-shadow: 0 20px 20px rgba(0,0,0,.16);
		transform: translate(0,-5px);
		transition-delay: 0s !important;
	}
}

.card__content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 15px;
}

.card__image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top-left-radius: 0.1em;
	border-top-right-radius: 0.1em;
	overflow: hidden;
	position: relative;
	&::before {
		content: "";
		display: block;
    padding-top: 56.25%; // 16:9 aspect ratio
}
@media(min-width: 40rem) {
	&::before {
      padding-top: 66.6%; // 3:2 aspect ratio
  }
}
}

.card__image--portfolio {
	background-image: url('/assets/img/thumbnails/portfolio-thumb.png');
}

.card__image--azteclive {
	background-image: url('/assets/img/thumbnails/azteclive-thumb.png');
}

.card__image--wallobee {
	background-image: url('/assets/img/thumbnails/wallobee-thumb.png');
}

.card__image--gotchu {
	background-image: url('/assets/img/thumbnails/gotchuapp-thumb.png');
}

.card__title {
	font-size: 1.2em;
	font-family: $Oswald;
	font-weight: 400;
	color: $dark-gray;
	text-transform: uppercase;
}

.card__text {
	font-family: $Lato;
	font-size: 0.85em;
	font-weight: 400;
	text-align: left;
	line-height: 20px;
	flex: 1 1 auto;
	margin-bottom: 1.25rem;
	color:$medium-gray;
	height: 3em;
}



