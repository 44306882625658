.project .project-overlay {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 100%;
}

.cover-project {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 260px;
    position: relative;
    margin: auto;
    z-index: 100;
}

.cover-project h1 {
    text-align: center;
    vertical-align: middle;
    margin: auto;
    color: $beige;
    font-family: $Oswald;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding-top: 40px;
}

.project-content {
    margin-top: 30px;
    overflow: hidden;
    text-align: justify;
}

.cover-list {
    list-style: none;
    text-align: center;
    padding-top: 30px;
    padding-left: 0px;
    li {
        color: $beige;
        font-size: 0.6em;
        font-family: $Lato;
        font-weight: 300;
        b {
            font-size: 1.2em;
            color: $beige;
            text-transform: uppercase;
            margin-right: 5px;
            font-family: $Oswald;
            letter-spacing: 4px;
        }
    }
}

.cover-overlay {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    color: #fff;
}

#azteclive-project {
    background-image: url("../img/thumbnails/azteclive-thumb.png");
}

#azteclive-cover {
    background-image: url("../img/thumbnails/azteclive-thumb.png");
}

#gotchuapp-project {
    background-image: url("../img/thumbnails/gotchuapp-thumb.png");
}

#gotchuapp-cover {
    background-image: url("../img/thumbnails/gotchuapp-thumb.png");
}

#arakotozafy-project {
    background-image: url("../img/thumbnails/portfolio-thumb.png");
}

#arakotozafy-cover {
    background-image: url("../img/thumbnails/portfolio-thumb.png");
}

#wallobee-project {
    background-image: url("../img/thumbnails/wallobee-thumb.png");
}

#wallobee-cover {
    background-image: url("../img/thumbnails/wallobee-thumb.png");
}

#sensefiction-project {
    background-image: url("../img/thumbnails/sensefiction-thumb.png");
}

#sensefiction-cover {
    background-image: url("../img/thumbnails/sensefiction-thumb.png");
}

#azteclive-project-2 {
    background-image: url("../img/Thumbnails/Background.jpg");
}

.cover-project {
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.25);
    margin-top: 15px;
    .cover-overlay {
        border-radius: 5px;
    }
}

.portfolio-img {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.025);
    width: 100%;
}

.arakotozafy-img {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
}

.google-slide {
    overflow: hidden;
    height: 600px;
    width: 100%;
}

.video-youtube {
    overflow: hidden;
    height: 600px;
    width: 100%;
}

.anim-youtube {
    overflow: hidden;
    height: 350px;
    width: 30%;
}

@media (max-width: 992px) {
    .anim-youtube {
        width: 100%;
        margin-bottom: 20px;
    }
}