.navbar-perso-home {
	border-color: transparent !important;
	background-color:transparent !important;
}
.navbar-right-home {
	li {
		a {
			color:$beige !important;
		}
	}
}
@media (max-width: 768px){
	.bg-cover-home {
		background-color: $theme-color !important;
	}
	.homepage-burger-home{
		background-color: $beige !important;
		background-image:none !important;
	}
	.menu-home {
		li a{
			color: $beige !important;
		}
		.first-item-menu{
			border-bottom: 1px solid $beige !important;
		}

		a li:hover {
			color: $beige !important;
		}
	} 
}