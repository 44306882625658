.app-header {
  text-align: center;
  width: 100%;
  margin-top:100px;
}

.app-header__title {
  font-size: 2em;
  font-family: $Lato;
  font-weight: 700;
  line-height: 0.5em;
  color:$theme-color;
  position: relative;
}

.app-header__subtitle {
  font-size: 1.2em;
  font-family: $Lato;
  font-weight: 400;
  color:$text-color;
}