//@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Oswald:300,500,600);

$thin : 100;
$thin-italic : '100 italic';
$light : 300;
$light-italic : '300italic';
$normal : 400;
$normal-light : '400italic';
$bold : 700;
$bold-italic : '700italic';
$ultra-bold : 900;
$ultra-bold-italic : '900italic';

$Lato:  'Lato', 'Arial', sans-serif;
$Oswald: 'Oswald', 'Arial', sans-serif;
