.project-list-item-custom{
  overflow: hidden;
  height: 300px;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
  opacity:0.94;
  &:hover {
    transition: all 250ms cubic-bezier(.02, .01, .47, 1); 
    opacity:1.0;
    transition-delay: 0s !important;
  }
}

.new-portfolio{
	padding:0px;
	margin-bottom:-20px; //Cancel the margin top from the footer
	margin-top:80px; //Height of the nav
}

.featured-projects{
  padding:0;
}

.portfolio-header{
  text-align: center;
  margin-top:80px;
  .portfolio-title{
    font-size: 2.5em;
    text-align: left;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $Oswald;
    position: relative;
    color: $theme-color;
    background-image: -webkit-linear-gradient(90deg, #3175AF 0%, #1F5786 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 60s infinite linear;
  }
  .portfolio-filter{
    list-style:none;
    text-align:left;
    padding:0px;
    li{
      padding:10px 15px;
      margin:10px 10px;
      border-radius:5px;
      border:1px solid black;
      word-break:break-all; /* wrap extended text*/
      display:inline-block;
      &:first-child{
        margin:10px 10px 10px 0px;
      }
    }
  }
}

.corner-ribbon{
  width: 200px;
  background: #854d23;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: $beige;
  font-family:$Lato;
  font-size:0.8em;
  font-weight:700;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
