.navbar-perso {
	height: 80px;
	border-color: transparent !important;
}
#bs-example-navbar-collapse-1 {
	position: relative;
	margin: 0px;
	padding: 0px;
	top: 20px;
} 

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
	background-color: transparent !important;
}
.navbar-right {

	li {
		a {
			font-size: 1.3em;
			font-family: $Oswald;
			line-height: 0.3em;
			font-weight: 500;
			color:$theme-color-full;
			text-transform:uppercase;
			letter-spacing: 3px;
			margin:0px 10px;
		}
	}
}

.hamburger-shell, .menu {
	visibility: hidden;
}
@media (max-width: 768px){
	.bg-cover {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		z-index: 0;
		transition: all 350ms ease;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	.reveal {
		opacity: .85;
		display: block;
		pointer-events: auto;
		visibility: visible;
	}

	.hamburger-shell {
		margin: 0;
		position: fixed;
		overflow: hidden;
		width: 70px;
		height: 100px;
		overflow: auto;
		left: calc(100% - 100px);
		top: 5px;
		visibility: visible;
	}

	.top, .middle{
		position: absolute;
		width: 60px;
		height: 10px;
		border-radius: 3px;
		background-color: rgba(35, 90, 133, 0.95);
		transition: all 350ms ease-in-out;
		top: 20px;
		background-image: -webkit-linear-gradient(90deg, #3175AF 0%, #1F5786 80%);
	}



	.middle {
		top: 40px;
	}

	.menu {
		position: fixed;
		left: calc(100% - 292px);
		color: black;
		display: none;
		margin: 120px 60px;
		text-decoration: none;
		font-family: Arial, sans-serif;
		font-weight: bold;
		font-size: 20px;
		text-transform: uppercase;
		color: #333;
		width: 200px;
		text-align: right;
		visibility: visible;
		list-style-type: none;
	}

	.menu {
		li {
			text-decoration: none!important;
			padding-bottom: 10px;
			margin-bottom: 12px;
			list-style: none;
			color: $theme-color;
			transition: all 150ms ease;
			button{
				border-style: none;
			}
		}
		.first-item-menu{
			border-bottom: 1px solid $theme-color;
		}
	}

	.menu a li:hover {
		color: rgba(35, 90, 133, 0.95);
		padding-right: 3px;
	}

	.menu a {
		text-decoration: none;
	}

	.rotate {
		transform: rotate(225deg);
		-webkit-transform: rotate(225deg);
		top: 30px;
		background-color: rgba(35, 90, 133, 0.95);
		height: 2px;
	}

	.rotate-back {
		transform: rotate(-225deg);
		-webkit-transform: rotate(-225deg);
		top: 30px;
		background-color: rgba(35, 90, 133, 0.95);
		height: 2px;
	}

	.top {
		-webkit-animation-delay: 100ms;
	}

	.middle {
		-webkit-animation-delay: 250ms;
	}

	.bottom {
		-webkit-animation-delay: 400ms;
	}
}

.navbar-brand-perso {
  padding: 10px 15px;
  float: left; 
  z-index:1000;
}

.navbar-brand-perso img {
  width: 60px;
  height: 60px;
}