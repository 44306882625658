.error-404{
  width:100%;
  display: block;
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden; 
  padding: 0px;
  margin: 0px;
}

.hero-picture-404{
  width:100%;
  height: 100%;
  background: url("../img/Background-404.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
}

.content-404 {
  font-family: $Lato;
  color: $beige;
  z-index: 2;
  position: absolute;
  top: 55%;
  left:5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  .title-404 {
    font-size: 15em;
    margin-bottom: 0;
    margin-top: 0;
    font-family:$Oswald;
    letter-spacing:2px;
    @media (max-width: 480px){
      text-align:center;
    }
    @media (max-width: 400px){
      font-size: 60vw;
    }
  }
  .subtitle-404 {
    margin-top: 0;
    max-width: 700px;
    font-size: 2.5em;
    width: 100%;
    text-transform:uppercase;
    font-family: $Oswald;
    margin-top:30px;
    @media (max-width: 480px){
      text-align:center;
    }
    @media (max-width: 400px){
      font-size: 10vw;
    }
  }
}

.btn-404 {
  display: inline-block;
  background-color: $theme-color;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 15px 30px;
  margin-bottom: 10px;
  color:$beige;
  font-family:$Lato;
  font-weight:700;
  font-size:1em;
  transition: all 250ms cubic-bezier(.02, .01, .47, 1);
  text-align: center;
  width: 100%;
  &:hover {
    background: $theme-color-full;
    box-shadow: 0 10px 30px rgba(0,0,0,0.8);
    transform: translate(0,-5px);
    transition-delay: 0s !important;
  }
}

@media only screen and (max-width: 480px) {
  .content-404 {
    padding:2%;
    left:0%;
  }
}