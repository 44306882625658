.arakotozafy-footer{
	background-color:$theme-color-full;
	margin:auto;
	padding:auto;
	margin-top:20px;
	.container{
		.row{
			height:100%;
			margin:auto;
			padding:30px 0px;
			.footer-element {
				color: $beige;
				margin:auto;
			}
			.footer-social{
				list-style-type: none;
				margin: 0;
				float:right;
				li {
					display: inline;	
					padding:7px;		
					i{
						color:$beige;
						font-size:1.5em;
					}
				}
			}
		}
	}
	//background-image: -webkit-linear-gradient(-90deg, #3175AF 0%, #1F5786 80%);
}

@media (max-width: 540px){
	.arakotozafy-footer{
		.container{
			.row{
				.copyright{
					font-size:0.8em;
				}
				.social{
					.footer-social{
						li {	
							padding:4.5px;		
							i{
								font-size:1.0em;
							}
						}
					}

				}
			}
		}
	}
}