.main-hero-text h1 {
  font-size: 4em;
  color: rgba(255, 255, 255, 0.95);
  vertical-align: middle;
  margin: auto;
  padding: 10px;
}
.home-h1{
  width: 75%;
  padding: 0px;
  display: block;
  text-align: center;
  margin: auto;
  background-color: $theme-color-half;
}
.main-hero-text h2 {
  font-size: 2.2em;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
}
.home-title{
  text-align: center;
  margin-top:35%;
}
.main-hero-text {
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  color: #fff;
  transition: color .3s ease,opacity .3s ease .65s;
  z-index: 7;
}