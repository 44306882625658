@media (max-width: 768px){

	/********** GENERAL **********/
	.home-h1{
	  width: 100%;
	}
	.home-title{
	  margin-top:60%;
	}
	.navbar-toggle-perso {
		margin:10px 15px !important;
	}
	.navbar-default .navbar-toggle {
    border-color: transparent !important;
	}
	.navbar-toggle .icon-bar {
	    width: 40px !important;
	    height: 5px !important;
	    border-radius: 10px !important;
	    margin: 7px 5px !important;
	    background-color:rgba(35, 90, 133, 0.95) !important;
	}
	.navbar-default .navbar-nav>li>a {
    	color: #fff;
	}
	#bs-example-navbar-collapse-1 {
    	top: 0px;
	}
	.navbar-default .navbar-collapse{
	    border-color: transparent;
	}
	header {
		top:0px;
	}

	/********* HOMEPAGE ***********/

	.social-network {
	  text-align: center;
	  font-family: "Open Sans";
	  font-weight: 300;
	  position: fixed;
	  top: 96%;
	  width:100%;
	  right:0%;
	}
	.social-network ul {
	  list-style: none;
	  margin: 0;
	  padding: 0;
	  background-color:rgba(255, 255, 255, 0.85);
	}
	.social-network ul li {
	  display: inline-block;
	  zoom: 1;
	  *display: inline;
	  vertical-align: middle;
	  width:32%;
	}
	.social-network ul li:hover {
	  background-color:rgba(255, 255, 255, 1);
	}
	.social-network ul li a {
	  display: block;
	  font-size: 1.4em;
	}
	.social-network ul li a i{
		color:rgba(35, 90, 133, 0.95);
	}
	.social-network ul li a i:hover{
		color:rgba(35, 90, 133, 1);
	}
	.main-hero-text h1 {
	  font-size:3em;
	}
	.main-hero-text h3{
	  font-size:1.3em ;
	  font-weight:normal;
	}

	/********** ABOUT **********/
	.mobile-content{
		position:fixed;
	}

	/******** PORTFOLIO *********/
   	.project h2{
   		position: relative;
   		font-size:2em;
   		top:60%;
   		left:50px;
   		font-weight: bold;
   	}
   	.project h3{
   		position: relative;
   		font-size:1.3em;
   		top:56%;
   		left:50px;
   		font-weight: 300;
   	}
   	.project h6{
   		position: relative;
   		top: -16%;
   		left: 70%;
   		border: 1px solid white;
   		padding: 10px;
   		width: 135px;
   		background: rgba(0,0,0,0.5);
   		font-weight: 500;
   		font-size: 1em;
   	}
   	.project .project-button{
   		color: white;
   		height: 45px;
   		background-color: rgba(35, 90, 133, 0.95);
   		position: relative;
   		padding: 10px;
   		font-weight: bold;
   		font-size: 1.2em;
   		box-shadow: 4px 4px 11px 2px rgba(0,0,0,0.7);
   		top: 185px;
   		left: 50px;
   		width: 150px;
   		border-color: transparent;
   		border-radius: 2px;
   	}
   	.video-youtube{
      height:300px;
    }
    .google-slide{
    	height:300px;
    }
}
@media (max-width: 550px){
	.project{
		height: 350px;
		margin-top: 110px;
	}
	.project h2,h3{
		left:35px !important;
	}
	.project h2{
		top:60%;
	}
	.project h3{
		top:55%;
	}
	.project h6{
		top:-20%;
		left:62%;
	}
	.project .project-button{
		top: 145px;
		left: 7%;
		width: 150px;
	}
}
@media (max-width: 414px){
	.project{
		height: 290px;
		margin-top: 100px;
	}
	.project h2{
		top:45%;
	}
	.project h3{
		top:40%;
	}
	.project h6{
		top:-25%;
		left:58%;
	}
	.project .project-button{
		top: 75px;
		left: 10%;
		width: 145px;
	}
	.main-hero-text h1 {
    	font-size: 2.5em;
	}
	.main-hero-text h2 {
    	font-size: 1.6em;
	}
}
@media (max-width: 375px){
	.project h6{
		right:-55%;
	}
}

@media (max-width: 320px){
	.project h2{
		font-size: 1.8em;
	}
	.project h3{
		font-size: 1.2em;
	}
	.project h6{
		right:-50%;
	}
	.project .project-button{
		font-size: 1em;
	}
}
