$white: #FFFFFF;
$white-alabaster: #FAFAFA;
$black-mine-shaft: #222;
$blue-cloud-burst: #20334E;
$beige: #F7F7F5;
$theme-color: rgba(35, 90, 133, 0.95);
$theme-color-contact: rgba(35, 90, 133, 0.85);
$theme-color-full: #1F5786;
$theme-color-half: rgba(35, 90, 133, 0.60);
$dark-gray: #696969;
$medium-gray: #4B4F56;
$text-color:#383838;
$first-color-gradient:#3175AF;