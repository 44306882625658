.modal-footer{
    border-top:transparent !important;
}

.modal-dialog{
    margin:70px auto !important;
    @media (max-width: $screen-xs-max){
        margin:50px 10px !important;
    }
}

.modal-content{
    -moz-transform: translateY(0.25em);
    -webkit-transform: translateY(0.25em);
    -ms-transform: translateY(0.25em);
    transform: translateY(0.25em);
    -moz-transition: opacity 0.325s ease-in-out, -moz-transform 0.325s ease-in-out;
    -webkit-transition: opacity 0.325s ease-in-out, -webkit-transform 0.325s ease-in-out;
    -ms-transition: opacity 0.325s ease-in-out, -ms-transform 0.325s ease-in-out;
    transition: opacity 0.325s ease-in-out, transform 0.325s ease-in-out;
    padding: 3em 2.5em 0.5em 2.5em ;
    position:relative;
    max-width: 100%;
    background-color: $theme-color-contact;
    border-radius: 0.3em;
}

.modal-title{
    border-bottom: solid 1px #ffffff;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -ms-max-content;
    width: max-content;
    padding-bottom: 0.5em;
    margin: 0 0 0.5em 0;
    color:$beige;
    font-family:$Oswald;
    font-weight:400;
    text-transform: uppercase;
    font-size:2.5em;

}

.close{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 4em;
    height: 4em;
    cursor: pointer;
    text-indent: 4em;
    overflow: hidden;
    white-space: nowrap;
    &:before{
        transition: background-color 0.2s ease-in-out;
        content: '';
        display: block;
        position: absolute;
        top: 0.75em;
        left: 0.75em;
        width: 2.5em;
        height: 2.5em;
        border-radius: 100%;
        background-position: center;
        background-image:url('../img/error.svg');
        background-size: 40px;
        background-repeat: no-repeat;
    }
}

    /*
    Dimension by HTML5 UP
    html5up.net | @ajlkn
    Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
    */
    

    /* Form */

    form .field {
        margin: 0 0 1.5em 0;
        label {
            color: #ffffff;
            display: block;
            font-size: 0.9em;
            font-weight: 400;
            font-family: $Lato;
            letter-spacing: 0.2em;
            line-height: 1.5;
            margin: 0 0 1em 0;
            text-transform: uppercase;
        }

    }

    ul.actions{
        cursor: default;
        list-style: none;
        padding-left: 0;
        display:flex;
        li{
            display: inline-block;
            padding: 0 1em 0 0;
            vertical-align: middle;
            text-align: center;
            > * {
                width: 100%;
                margin: 0 !important;
            }
            &.half-btn{
                width: 50%;
                float: left;
                padding: 0 0 0 0.75em;
            }
            &.first-btn{
                width: 50%;
                float: left;
                padding: 0 0.75em 0 0;
            }
        }
    }

    form .field.half {
        width: 50%;
        float: left;
        padding: 0 0 0 0.75em;
    }

    form .field.half.first {
        padding: 0 0.75em 0 0;
    }

    form > .actions {
        margin: 1.875em 0 0 0 !important;
    }

    @media screen and (max-width: 736px) {

        form .field {
            margin: 0 0 1.125em 0;
        }

        form .field.half {
            padding: 0 0 0 0.5625em;
        }

        form .field.half.first {
            padding: 0 0.5625em 0 0;
        }

        form > .actions {
            margin: 1.5em 0 0 0 !important;
        }

    }

    @media screen and (max-width: 480px) {
        ul.actions {
            display: inline;
            li {
                padding: 1em 0 0 0;
                display: block;
                text-align: center;
                width: 100%;
                &.half-btn{
                    width: 100%;
                    padding:0;
                    margin: 20px 0px 0px 0px;
                }
                &.first-btn{
                   width: 100%;
                   padding:0;
               }

           }
           &:after {
            clear: both;
            content: ".";
            display: block;
            height: 0;
            line-height: 0;
            visibility: hidden;
        }
    }
    ul.actions li > * {
        width: 100%;
        margin: 0 !important;
    }
    .modal-dialog{
        margin:30px 10px !important;
    }
    form .field.half {
        width: 100%;
        float: none;
        padding: 0;
    }

    form .field.half.first {
        padding: 0;
    }

}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -webkit-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -ms-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background: transparent;
    border-radius: 4px;
    border: solid 1px #ffffff;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
    &::invalid{
        box-shadow:none;
    }
    &:focus{
        background: rgba(255, 255, 255, 0.075);
        border-color: #ffffff;
        box-shadow: 0 0 0 1px #ffffff; 
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 2.75em;
}

textarea {
    padding: 0.75em 1em;
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1.0;
}

:-moz-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1.0;
}

::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1.0;
}

:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1.0;
}

.formerize-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1.0;
}




/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
{
    line-height: calc(2.75em - 2px);
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: transparent;
    border-radius: 4px;
    border: 0;
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: 400;
    height: 3.5em;
    letter-spacing: 0.2em;
    line-height: 2.75em;
    outline: 0;
    padding: 0 1.25em 0 1.35em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover{
        background-color: rgba(255, 255, 255, 0.075);
    }
    &:active{
        background-color: rgba(255, 255, 255, 0.175);
    }
    .icon:before{
        margin-right: 0.5em;
    }
    &.fit{

        display: block;
        margin: 0 0 1em 0;
        width: 100%;
    }
    &.special{
        background-color: #ffffff;
        color: #1b1f22 !important;
        font-weight: 600;
    }
    &.disabled{
     -moz-pointer-events: none;
     -webkit-pointer-events: none;
     -ms-pointer-events: none;
     pointer-events: none;
     cursor: default;
     opacity: 0.25;
 }
 &:disabled{
     -moz-pointer-events: none;
     -webkit-pointer-events: none;
     -ms-pointer-events: none;
     pointer-events: none;
     cursor: default;
     opacity: 0.25;
 }
}