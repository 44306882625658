.home-subtitle{
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: $Oswald;
  position: relative;
  color: #5b85c0;
  background-image: -webkit-linear-gradient(90deg, #3175AF 0%, #1F5786 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 60s infinite linear;
}

.home-about{
  padding-top:10px;
  padding-bottom: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.home-about__picture{
  margin: 20px auto 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  box-shadow:0 0 10px 0px rgba(0, 0, 0, 0.5);
}

.home-about__description{
  text-align:justify;
  color:$text-color;
}

.home-about__resume{
  text-align:center;
  background-color: rgba(255,255,255,0.6);
  padding: 10px;
  width: 250px;
  margin: auto;
  box-shadow: 0 0 4px 0px rgba(0,0,0,0.2);
  margin-top: 15px;
  a{
    font-size:0.85em;
  }
}

.home-about__links{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-left:0px;
  li{
    text-transform: uppercase;
    font-size: .8em;
    opacity: .6;
    transition: .2s ease;
    list-style: none;
    a{
      color:$theme-color !important;
      opacity:1;
    }
    a:hover{
      color:$theme-color-full !important;
      text-decoration: none !important;
    }
    &:hover{
      opacity: 1;
    }
    &:not(:last-child){
      margin-right: 14px;
    }
  }
}
.home-projects{
  width: 100%;
  padding-bottom: 20px;
  padding-left:0px;
}
