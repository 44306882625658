*,
*::before,
*::after { 
  box-sizing: border-box !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  font-size: 1.6rem;
  font-family: $Lato;
  line-height: 1.5;
  font-weight: 400;
  background-color: $beige;
  color:$text-color;
}

.content {
  margin-top: 80px;
}

a{
  text-decoration: none;
  cursor: pointer;
  opacity: 0.9;
  color:#23527c !important;
  &:hover{
    text-decoration:none;
    opacity:1.0;
    transition: .2s ease;
  }
}
p{
  font-family:$Lato;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

input, select, textarea {
  color: #ffffff;
  font-family: $Lato;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.65;
}

form {
  margin: 0 0 2.5rem 0;
}