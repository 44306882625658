 .demo-gallery {
  > ul {
    margin-bottom: 0;
    > li {
      float: left;
      margin-bottom: 15px;
      margin-top: 15px;
      a {
        box-shadow:0 0 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        display: block;
        overflow: hidden;
        position: relative;
        float: left;
        > img {
          -webkit-transition: -webkit-transform 0.15s ease 0s;
          -moz-transition: -moz-transform 0.15s ease 0s;
          -o-transition: -o-transform 0.15s ease 0s;
          transition: transform 0.15s ease 0s;
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
          height: 100%;
          width: 100%;
        }
        &:hover {
          > img {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
          }
        }
      }
    }
  }
}