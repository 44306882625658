.main-hero {
  width: 100%;
  display: block;
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  transition: all .3s ease; 
}
.hero-picture{
  height: 100%;
  background: url("../img/Background.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
}